<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="deptName" @md-selected="getDeptSelected" :md-options="depts" @md-changed="getDepts"  @md-opened="getDepts" :class="{'md-invalid': submitted && $v.entity.deptId.$error }">
                                    <label>Phòng ban</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.deptId.isSelected">Vui lòng chọn phòng ban</span>
                                </md-autocomplete>
                                <md-button @click="openDept()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="leadName" @md-selected="getLeadSelected" :md-options="leads" @md-changed="getLeads"  @md-opened="getLeads">
                                    <label>Lead</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openLead()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.teamName.$error }">
                                <label for="teamName">Tên nhóm</label>
                                <md-input name="teamName" v-model="entity.teamName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.teamName.required">Vui lòng nhập tên nhóm</span>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="tool-bar">
                                <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                            </div>
                            <table class="data-table-2"> 
                                <thead> 
                                    <tr> 
                                        <th style="width:100px;">#</th> 
                                        <th style="width:25%;">Bộ phận/phòng</th> 
                                        <th style="width:20%;">Mã nhân viên</th> 
                                        <th style="width:35%;">Tên nhân viên</th>
                                        <th style="width:15%">Hành động</th> 
                                    </tr> 
                                </thead> 
                                <tbody v-if="loadding == true" style="height: 100px;"> 
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false"> 
                                    <tr v-for="(item, index) in entity.members" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                        <td class="center">{{index + 1}}</td>
                                        <td class="center">
                                            <div class="grid-control">
                                                <input v-model="item.department.name" class="form-control" type="text">
                                                <md-button @click="openDeptMember(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm bộ phận</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="center">
                                            <div class="grid-control">
                                                <input v-model="item.code" :class="{'is-error': $v.entity.members.$each[index].id.$error}" class="form-control" type="text">
                                                <md-button @click="openMember(item.id)" class="md-icon-button">
                                                    <md-icon>manage_search</md-icon>
                                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                                </md-button>
                                            </div>
                                        </td> 
                                        <td class="left">
                                            {{ item.fullName }}
                                        </td>
                                        <td class="grid-action">
                                            <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                        </td> 
                                    </tr> 
                                </tbody> 
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <deptList ref="deptList" @close="closeDept"/>
        <leadList ref="leadList" @close="closeLead"/>
        <memberList ref="memberList" @close="closeMember"/>
        <deptMemberList ref="deptMemberList" @close="closeDeptMember"/>
   </div>
</template>
<script>
    import teamService from '../../../api/teamService';
    import staffService from '../../../api/staffService';
    import departmentService from '../../../api/departmentService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import deptList from '../../../components/popup/_DepartmentList.vue'
    import leadList from '../../../components/popup/_StaffList.vue';
    import memberList from '../../../components/popup/_StaffList.vue';
    import deptMemberList from '../../../components/popup/_DepartmentList.vue';

    export default ({
        components: {
            deptList,
            leadList,
            deptMemberList,
            memberList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật nhóm'
        },
        data() {
            return {
               title: '',
               id: 0,
               loadding: false,
               submitted: false,
               entity: { id: 0, deptId: 0, leadId: null, teamName: '', members: [] },
               depts: [],
               deptName: '',
               leads: [],
               leadName: '',
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật nhóm';
               this.getById();
            }
            else{
               this.title = 'Thêm mới nhóm';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                teamService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                teamService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/team');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                teamService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.deptName = this.entity.dept.name;
                        if(this.entity.leadId > 0){
                            this.leadName = this.entity.lead.fullName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Member
            delRow(item){
                const index = this.entity.members.findIndex(x => x.id == item.id);
                this.entity.members.splice(index, 1);
            },

            addRow(){
                let item = { id: 0, department: { id: 0, code: '', name: '' }, staff: { fullName: '', code: '' }, staffId: 0 };
                this.entity.members.push(item);
            },

            closeMember(item){
                const selected = this.entity.members.findIndex(x => x.id == this.selectedId);
                this.entity.members[selected].code = item.code;
                this.entity.members[selected].fullName = item.fullName;
                this.entity.members[selected].id = item.id;
                this.entity.members[selected].department = item.department;
                this.$refs.memberList.close();
                this.selectedId = '';
            },

            openMember(id){
                this.selectedId = id;
                const index = this.entity.members.findIndex(x => x.id == id);
                const deptId = this.entity.members[index].department.id;
                this.$refs.memberList.open(deptId);
            },

            //DEPT
            closeDeptMember(item){
                const selected = this.entity.members.findIndex(x => x.id == this.selectedId);
                this.entity.members[selected].department = item;
                this.$refs.deptMemberList.close();
                this.selectedId = '';
            },

            openDeptMember(id){
                this.selectedId = id;
                this.$refs.deptMemberList.open();
            },

            //Lead
            closeLead(item){
                this.leadName = item.fullName;
                this.entity.leadId = item.id;
                this.$refs.leadList.close();
            },

            getLeadSelected(val){
                this.entity.leadId = val.id;
                this.leadName = val.fullName;
            },

            openLead(){
                this.$refs.leadList.open();
            },

            getLeads(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.leads = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeDept(item){
                this.deptName = item.name;
                this.entity.deptId = item.id;
                this.$refs.deptList.close();
            },

            getDeptSelected(val){
                this.entity.deptId = val.id;
                this.deptName = val.name;
            },

            openDept(){
                this.$refs.deptList.open();
            },

            getDepts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                departmentService.getDepartments(search).then((response) => {
                    if(response.statusCode == 200){
                        this.depts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/team');
            },
        },
        watch: { 
            deptName: function (val) { 
                if(val == ''){
                    this.entity.departmentId = 0;
                }
            },
            leadName: function (val) { 
                if(val == ''){
                    this.entity.leadId = null;
                }
            }
        },
        validations: {
            entity: {
                deptId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                teamName: { required },
                members: {
                    $each: {
                        id: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
    })

</script>
